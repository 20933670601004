* {
    max-width: 100%;
}

form {
    height: '50vh'
}

@media only screen 
    
    and (min-device-width: 375px)

    and (orientation: portrait) { 
    }